import { render, staticRenderFns } from "./desktop.vue?vue&type=template&id=1ea37b7d&scoped=true&"
import script from "./desktop.vue?vue&type=script&lang=js&"
export * from "./desktop.vue?vue&type=script&lang=js&"
import style0 from "./desktop.vue?vue&type=style&index=0&id=1ea37b7d&lang=scss&scoped=true&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "1ea37b7d",
  null
  
)

export default component.exports

/* nuxt-component-imports */
installComponents(component, {FooterMobile: require('/app/components/footer/mobile.vue').default,CarouselCard: require('/app/components/carousel/card/index.vue').default,ProcessListItem: require('/app/components/process/list/item.vue').default,InfopackListItem: require('/app/components/infopack/list/item.vue').default,ProcessCardFullHorizontal: require('/app/components/process/card/full/horizontal.vue').default,InfopackCardFullHorizontal: require('/app/components/infopack/card/full/horizontal.vue').default,IContainer: require('/app/components/i/container/index.vue').default})


/* vuetify-loader */
import installComponents from "!../../../node_modules/vuetify-loader/lib/runtime/installComponents.js"
import { VBtn } from 'vuetify/lib/components/VBtn';
import { VCard } from 'vuetify/lib/components/VCard';
import { VChip } from 'vuetify/lib/components/VChip';
import { VContainer } from 'vuetify/lib/components/VGrid';
import { VDivider } from 'vuetify/lib/components/VDivider';
import { VIcon } from 'vuetify/lib/components/VIcon';
import { VImg } from 'vuetify/lib/components/VImg';
import { VList } from 'vuetify/lib/components/VList';
import { VListItem } from 'vuetify/lib/components/VList';
import { VSkeletonLoader } from 'vuetify/lib/components/VSkeletonLoader';
installComponents(component, {VBtn,VCard,VChip,VContainer,VDivider,VIcon,VImg,VList,VListItem,VSkeletonLoader})


/* vuetify-loader */
import installDirectives from "!../../../node_modules/vuetify-loader/lib/runtime/installDirectives.js"
import Intersect from 'vuetify/lib/directives/intersect'
installDirectives(component, {Intersect})
