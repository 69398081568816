import { render, staticRenderFns } from "./index.vue?vue&type=template&id=6c7ca355&scoped=true&"
import script from "./index.vue?vue&type=script&lang=js&"
export * from "./index.vue?vue&type=script&lang=js&"
import style0 from "./index.vue?vue&type=style&index=0&id=6c7ca355&lang=scss&scoped=true&"


/* normalize component */
import normalizer from "!../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "6c7ca355",
  null
  
)

export default component.exports

/* nuxt-component-imports */
installComponents(component, {HeaderUserMobile: require('/app/components/header/user/mobile.vue').default,IScrollableNavbar: require('/app/components/i/scrollable-navbar/index.vue').default,HeaderGuestMobile: require('/app/components/header/guest/mobile.vue').default,ILoading: require('/app/components/i/loading/index.vue').default,HomeUserMobile: require('/app/components/home/user/mobile.vue').default,HomeGuestMobile: require('/app/components/home/guest/mobile.vue').default,PMobileBottomMenu: require('/app/components/p/mobile-bottom-menu/index.vue').default,FooterMobile: require('/app/components/footer/mobile.vue').default,Header: require('/app/components/header/Header.vue').default,HomeUserDesktop: require('/app/components/home/user/desktop.vue').default,HomeGuestDesktop: require('/app/components/home/guest/desktop.vue').default})


/* vuetify-loader */
import installComponents from "!../node_modules/vuetify-loader/lib/runtime/installComponents.js"
import { VAppBar } from 'vuetify/lib/components/VAppBar';
installComponents(component, {VAppBar})
