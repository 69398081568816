import { mapActions } from 'vuex';
import checkActivation from '~/mixinsjs/active-account';

export default {
    mixins: [checkActivation],
    props: {
        homeData: {
            type: Object,
            required: true,
        },
    },
    data() {
        return {
            suggestedContent: {
                data: [],
                loading: false,
                limit: 3,
                offset: 0,
            },
        };
    },
    computed: {
        userInfo() {
            return this.$store.state.auth;
        },
    },
    mounted() {
        this.suggestedContent.data = this.homeData.suggested.docs;
    },
    methods: {
        ...mapActions({ setSnackBar: 'setSnackBar' }),
        async getMoreSuggestedContent() {
            if (
                this.suggestedContent.data.length >= this.homeData.suggested.total ||
                this.suggestedContent.loading
            ) {
                return;
            }
            this.suggestedContent.loading = true;
            try {
                this.suggestedContent.offset += 3;
                const response = await this.$homeService.getSuggestedAuthContent(
                    this.suggestedContent.limit,
                    this.suggestedContent.offset
                );
                this.suggestedContent.data.push(...response.docs);
            } catch (e) {
                this.suggestedContent.offset -= 3;
                this.setSnackBar({
                    toggle: true,
                    message:
                        e?.response?.data?.notification?.message ||
                        e?.data?.notification?.message ||
                        e?.message ||
                        this.$t('info.messages.anErrorOccurred'),
                    color: 'red',
                });
            }
            this.suggestedContent.loading = false;
        },
        createNewContent(content) {
            this.mxCheckActivation(() => {
                this.$router.push(`/${content}?create=true`);
            });
        },
        mobileCreateNewContent() {
            this.setSnackBar({
                toggle: true,
                message: this.$t('info.messages.useDesktopToCreateContent'),
                color: 'red',
            });
        },
        showAllContents(items, status) {
            const processesCount = items.filter(
                (itm) => itm.type === this.$Enums.PackType.PROCESS
            ).length;
            if (processesCount > items.length - processesCount) {
                this.$router.push(`/account/processes?status=${status}`);
            } else {
                this.$router.push(`/account/infopacks?status=${status}`);
            }
        },
    },
};
