//
//
//
//
//
//
//
//
//
//
//
//
//
//

export default {
    props: {
        scrollTopDistance: {
            type: Number,
            default: 300,
        },
        noMinHeight: {
            type: Boolean,
            default: false,
        },
        disableHiding: {
            type: Boolean,
            default: false,
        },
    },
    data() {
        return {
            lastScrollTop: 0,
            directionIsTop: true,
            showHiddenSlot: true,
            firstScrollPositionToTop: null,
        };
    },
    beforeMount() {
        this.setScrollVariables();
        window.addEventListener('scroll', this.onScroll);
        window.addEventListener('wheel', this.onScroll);
    },
    beforeDestroy() {
        window.removeEventListener('scroll', this.onScroll);
        window.removeEventListener('wheel', this.onScroll);
    },
    methods: {
        setScrollVariables() {
            this.lastScrollTop = document.documentElement.scrollTop;
            this.showHiddenSlot = this.lastScrollTop === 0;
        },
        showHiddenSection() {
            setTimeout(() => {
                this.showHiddenSlot = true;
            }, 10);
        },
        onScroll() {
            const scrollTop = window.pageYOffset || document.documentElement.scrollTop;
            if (scrollTop > this.lastScrollTop) {
                this.directionIsTop = false;
                this.firstScrollPositionToTop = null;
            } else {
                this.directionIsTop = true;
                if (!this.firstScrollPositionToTop) {
                    this.firstScrollPositionToTop = scrollTop;
                }
            }

            // scrolling more than "this.scrollTopDistance" px to top
            if (
                this.firstScrollPositionToTop &&
                Math.abs(this.firstScrollPositionToTop - scrollTop) > this.scrollTopDistance
            ) {
                this.showHiddenSlot = true;
            } else {
                this.showHiddenSlot = false;
            }

            if (this.lastScrollTop < 50) {
                this.showHiddenSlot = true;
            }

            this.lastScrollTop = scrollTop <= 0 ? 0 : scrollTop;
        },
    },
};
