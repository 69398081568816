//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

export default {
    props: {
        scrollable: {
            type: Boolean,
            default: false,
        },
        reverse: {
            type: Boolean,
            default: false,
        },
        lgSidebar: {
            type: Boolean,
            default: false,
        },
    },
};
