


























































































































































































































































































































































import dates from '@/mixinsjs/dates';
import packCommon from '@/mixinsjs/pack-common';

export default {
    mixins: [dates, packCommon],
    props: {
        theInfopack: {
            type: Object,
            default: () => {},
        },
    },
    computed: {
        icon() {
            if (
                this.theInfopack.myPermission?.userStatus === this.$Enums.ProcessUserStatus.ORIGINAL
            ) {
                return 'iconiz-publish';
            }
            if (
                this.theInfopack.myPermission?.userStatus === this.$Enums.ProcessUserStatus.COPIED
            ) {
                return 'iconiz-copy';
            }
            if (
                this.theInfopack.myPermission?.userStatus === this.$Enums.ProcessUserStatus.SHARED
            ) {
                return 'mdi-share-variant';
            }
            if (
                this.theInfopack.myPermission?.userStatus === this.$Enums.ProcessUserStatus.RUNNING
            ) {
                return 'iconiz-data-usage';
            }
            if (
                this.theInfopack.myPermission?.userStatus === this.$Enums.ProcessUserStatus.BOOKMARK
            ) {
                return 'iconiz-bookmark-outline';
            }
            if (
                this.theInfopack.myPermission?.userStatus ===
                this.$Enums.ProcessUserStatus.REGISTERED
            ) {
                return 'mdi-account-check-outline';
            }
            return '';
        },
        storage() {
            return process.env.VUE_APP_API_STORAGE_URL;
        },
        avatarStorage() {
            return this.mxStorage();
        },
        publishedDate() {
            return this.mxPublishedDate(this.theInfopack);
        },
        hasAvatar() {
            return this.mxHasAvatar(this.theInfopack);
        },
        targetUrl() {
            return this.mxTargetUrl(this.theInfopack);
        },
        price() {
            return this.mxPrice(this.theInfopack);
        },
    },
};
