//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import { mapActions } from 'vuex';
import checkActivation from '~/mixinsjs/active-account';

export default {
    mixins: [checkActivation],
    computed: {
        path() {
            if (this.$route.path === '/') {
                return 'home';
            }
            if (this.$route.path === '/chat') {
                return 'chat';
            }
            return '';
        },
    },
    methods: {
        ...mapActions('sidebar', { toggleSidebar: 'toggleSidebar' }),
        ...mapActions('search', { toggleMobileSearchPanel: 'toggleMobileSearchPanel' }),
        openSearchBox() {
            this.toggleMobileSearchPanel();
        },
        openDrawer() {
            this.toggleSidebar();
        },
        openChatPage() {
            this.mxCheckActivation(() => {
                this.$router.push(this.localePath('/chat'));
            });
        },
    },
};
