import { render, staticRenderFns } from "./mobile.vue?vue&type=template&id=87768748&"
import script from "./mobile.vue?vue&type=script&lang=js&"
export * from "./mobile.vue?vue&type=script&lang=js&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

export default component.exports

/* nuxt-component-imports */
installComponents(component, {ProcessCardParticipated: require('/app/components/process/card/participated.vue').default,InfopackCardVisitorMobile: require('/app/components/infopack/card/visitor/mobile.vue').default,ProcessCardManage: require('/app/components/process/card/manage.vue').default,InfopackCardManage: require('/app/components/infopack/card/manage.vue').default,ProcessCardFullVertical: require('/app/components/process/card/full/vertical.vue').default,InfopackCardFullVertical: require('/app/components/infopack/card/full/vertical.vue').default})


/* vuetify-loader */
import installComponents from "!../../../node_modules/vuetify-loader/lib/runtime/installComponents.js"
import { VBtn } from 'vuetify/lib/components/VBtn';
import { VList } from 'vuetify/lib/components/VList';
import { VListItem } from 'vuetify/lib/components/VList';
import { VSkeletonLoader } from 'vuetify/lib/components/VSkeletonLoader';
installComponents(component, {VBtn,VList,VListItem,VSkeletonLoader})


/* vuetify-loader */
import installDirectives from "!../../../node_modules/vuetify-loader/lib/runtime/installDirectives.js"
import Intersect from 'vuetify/lib/directives/intersect'
installDirectives(component, {Intersect})
