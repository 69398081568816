import { render, staticRenderFns } from "./mobile.vue?vue&type=template&id=ef6313a0&"
import script from "./mobile.vue?vue&type=script&lang=js&"
export * from "./mobile.vue?vue&type=script&lang=js&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

export default component.exports

/* nuxt-component-imports */
installComponents(component, {ILogo: require('/app/components/i/logo/index.vue').default,Header: require('/app/components/header/Header.vue').default})


/* vuetify-loader */
import installComponents from "!../../../node_modules/vuetify-loader/lib/runtime/installComponents.js"
import { VBtn } from 'vuetify/lib/components/VBtn';
import { VImg } from 'vuetify/lib/components/VImg';
installComponents(component, {VBtn,VImg})
