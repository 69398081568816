//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import { mapActions } from 'vuex';

export default {
    methods: {
        ...mapActions('search', { toggleMobileSearchPanel: 'toggleMobileSearchPanel' }),
        toggleSearchBox() {
            this.toggleMobileSearchPanel();
        },
    },
};
