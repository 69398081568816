//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

export default {
    name: 'HomePage',
    layout(context) {
        return context.$device.isDesktopOrTablet ? 'guest' : 'mobile';
    },
    data() {
        return {
            homeData: {},
        };
    },
    async fetch() {
        try {
            if (this.loggedIn) {
                this.homeData = await this.$homeService.getNewUserHome();
            } else {
                this.homeData = await this.$homeService.getGuestHome();
            }
        } catch (err) {
            // TODO handle error
            this.$nuxt.context.redirect('/oops');
        }
    },
    head() {
        return {
            title: this.$t('info.public.appTitle'),
            meta: [
                // hid is used as unique identifier. Do not use `vmid` for it as it will not work
                {
                    hid: 'description',
                    name: 'description',
                    content: this.$t('info.messages.appDescription'),
                },
            ],
        };
    },
    computed: {
        loggedIn() {
            return this.$store.state.auth?.loggedIn;
        },
        mobile() {
            return !this.$breakpoint.mdAndUp;
        },
    },
    watch: {
        loggedIn() {
            this.$fetch();
            window.scrollTo({
                top: 0,
                left: 0,
                behavior: 'smooth',
            });
        },
    },
};
