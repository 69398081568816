//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

export default {
    props: {
        theInfopack: {
            type: Object,
            default: () => {},
        },
        selfProfile: {
            type: Boolean,
            default: false,
        },
    },
    computed: {
        storage() {
            return process.env.VUE_APP_API_STORAGE_URL;
        },
        infopackTokenPrefix() {
            return this.selfProfile ? 'private-' : '';
        },
        hasPrice() {
            return this.theInfopack.subscriptions?.[0]?.price;
        },
    },
};
