//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

export default {
    props: {
        chapters: {
            type: Array,
            required: true,
        },
        baseUrl: {
            type: String,
            default: '',
        },
    },
};
