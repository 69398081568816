//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import { mapActions } from 'vuex';
import dates from '@/mixinsjs/dates';
import packCommon from '@/mixinsjs/pack-common';

export default {
    mixins: [dates, packCommon],
    props: {
        theProcess: {
            type: Object,
            default: () => {},
        },
    },
    data() {
        return {
            bottomDrawer: false,
            fullscreen: false,
            selectedMenu: null,
            selectedTab: null,
        };
    },
    computed: {
        icon() {
            if (
                this.theProcess.myPermission?.userStatus === this.$Enums.ProcessUserStatus.ORIGINAL
            ) {
                return 'iconiz-publish';
            }
            if (this.theProcess.myPermission?.userStatus === this.$Enums.ProcessUserStatus.COPIED) {
                return 'iconiz-copy';
            }
            if (this.theProcess.myPermission?.userStatus === this.$Enums.ProcessUserStatus.SHARED) {
                return 'iconiz-share';
            }
            if (
                this.theProcess.myPermission?.userStatus === this.$Enums.ProcessUserStatus.RUNNING
            ) {
                return 'iconiz-data-usage';
            }
            if (
                this.theProcess.myPermission?.userStatus === this.$Enums.ProcessUserStatus.BOOKMARK
            ) {
                return 'iconiz-bookmark-outline';
            }
            if (
                this.theProcess.myPermission?.userStatus ===
                this.$Enums.ProcessUserStatus.REGISTERED
            ) {
                return 'mdi-account-check-outline';
            }
            return '';
        },
        sortedInfopackTabs() {
            try {
                return [...(this.theProcess.infopackTabs || [])].sort((a, b) => a.order - b.order);
            } catch (error) {
                return this.theProcess.infopackTabs;
            }
        },
        currentTab() {
            if (!this.sortedInfopackTabs.length) return null;
            for (const tab of this.sortedInfopackTabs) {
                if (!tab.infopackTabOwners) {
                    return tab;
                }
                if (tab.infopackTabOwners.statusRef === this.$Enums.ProcessTaskStatus.STARTED)
                    return tab;
            }
            return null;
        },
        processIsFinished() {
            return this.theProcess.progress === 100;
        },
        daysRemain() {
            if (!this.currentTab) return 0;
            return this.mxDaysRemain(this.currentTab.expiresAt);
        },
        targetUrl() {
            return this.mxTargetUrl(this.theProcess);
        },
    },
    watch: {
        selectedMenu(val) {
            if (val === 'tabs') {
                this.selectedTab = 0;
            } else {
                this.selectedTab = 1;
            }
        },
    },
    methods: {
        ...mapActions({ setSnackBar: 'setSnackBar' }),
        closeBottomDrawer() {
            this.bottomDrawer = false;
            this.selectedMenu = null;
        },
        showAccessError() {
            this.setSnackBar({
                toggle: true,
                message: this.$t('process.noAccess'),
                color: 'red',
            });
        },
    },
};
