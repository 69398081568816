import { render, staticRenderFns } from "./desktop.vue?vue&type=template&id=eb5fafd0&scoped=true&"
import script from "./desktop.vue?vue&type=script&lang=js&"
export * from "./desktop.vue?vue&type=script&lang=js&"
import style0 from "./desktop.vue?vue&type=style&index=0&id=eb5fafd0&lang=scss&scoped=true&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "eb5fafd0",
  null
  
)

export default component.exports

/* nuxt-component-imports */
installComponents(component, {CarouselCard: require('/app/components/carousel/card/index.vue').default,FooterMobile: require('/app/components/footer/mobile.vue').default,PFullCard: require('/app/components/p/full-card/index.vue').default,IContainer: require('/app/components/i/container/index.vue').default})


/* vuetify-loader */
import installComponents from "!../../../node_modules/vuetify-loader/lib/runtime/installComponents.js"
import { VBtn } from 'vuetify/lib/components/VBtn';
import { VCard } from 'vuetify/lib/components/VCard';
import { VChip } from 'vuetify/lib/components/VChip';
import { VCol } from 'vuetify/lib/components/VGrid';
import { VContainer } from 'vuetify/lib/components/VGrid';
import { VDivider } from 'vuetify/lib/components/VDivider';
import { VImg } from 'vuetify/lib/components/VImg';
import { VList } from 'vuetify/lib/components/VList';
import { VListItem } from 'vuetify/lib/components/VList';
import { VRow } from 'vuetify/lib/components/VGrid';
import { VSkeletonLoader } from 'vuetify/lib/components/VSkeletonLoader';
installComponents(component, {VBtn,VCard,VChip,VCol,VContainer,VDivider,VImg,VList,VListItem,VRow,VSkeletonLoader})


/* vuetify-loader */
import installDirectives from "!../../../node_modules/vuetify-loader/lib/runtime/installDirectives.js"
import Intersect from 'vuetify/lib/directives/intersect'
installDirectives(component, {Intersect})
