var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-hover',{scopedSlots:_vm._u([{key:"default",fn:function(ref){
var hover = ref.hover;
return [_c('v-card',{staticClass:"d-flex align-center w-100 pa-2 my-2 rounded-lg p-relative hover-bg-gray",attrs:{"nuxt":"","to":_vm.localePath(_vm.targetUrl),"elevation":"0"}},[_c('v-img',{staticClass:"flex-basis-30 rounded-lg pa-2",attrs:{"aspect-ratio":16 / 10,"src":_vm.theInfopack.infopackAsset
                    ? (_vm.storage + "/" + (_vm.theInfopack.infopackAsset.filenameDisk))
                    : '/images/default-image.jpg'}}),_vm._v(" "),_c('div',{staticClass:"d-flex flex-column flex-basis-70 g-1 mi-w-0 px-2"},[_c('div',{staticClass:"head bg-linear-gradiant d-flex align-center w-100 mw-100 mi-w-0 px-2"},[_c('v-icon',{staticClass:"font-20 gray-9"},[_vm._v(_vm._s(_vm.icon))]),_vm._v(" "),_c('v-tooltip',{attrs:{"top":"","disabled":!_vm.$breakpoint.mdAndUp ||
                        !_vm.theInfopack.title ||
                        _vm.theInfopack.title.length < 60,"max-width":"500px","color":"rgba(0, 0, 0, 0.87)"},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
                        var on = ref.on;
                        var attrs = ref.attrs;
return [_c('h5',_vm._g(_vm._b({staticClass:"info-black font-16 text-clamp-1 pr-2"},'h5',attrs,false),on),[_vm._v("\n                            "+_vm._s(_vm.theInfopack.title || _vm.$t('info.public.untitled'))+"\n                        ")])]}}],null,true)},[_vm._v(" "),_c('span',[_vm._v(_vm._s(_vm.theInfopack.title))])]),_vm._v(" "),_c('div',{staticClass:"ms-auto d-flex align-center flex-grow-0"},[_c('v-fade-transition',[(hover)?_c('div',{staticClass:"actions d-flex justify-center align-center"},[(_vm.theInfopack.myPermission)?_c('v-btn',{attrs:{"depressed":"","color":"blue","icon":"","fab":"","small":""},on:{"click":function($event){$event.stopPropagation();$event.preventDefault();return _vm.$emit('pin-infopack', _vm.theInfopack.id)}}},[(
                                        _vm.theInfopack.myPermission && _vm.theInfopack.myPermission.pin
                                    )?_c('v-icon',{staticClass:"font-20 gray-8"},[_vm._v("mdi-pin")]):_c('v-icon',{staticClass:"font-20 gray-8"},[_vm._v("mdi-pin-outline")])],1):_vm._e(),_vm._v(" "),(
                                    _vm.theInfopack.myPermission &&
                                    _vm.theInfopack.myPermission.userStatus === 3
                                )?_c('v-btn',{attrs:{"depressed":"","color":"blue","icon":"","fab":"","small":""},on:{"click":function($event){$event.stopPropagation();$event.preventDefault();return _vm.$emit(
                                        'revoke-infopack',
                                        _vm.theInfopack.id,
                                        _vm.theInfopack.myPermission.id
                                    )}}},[_c('v-icon',{staticClass:"font-20 gray-8"},[_vm._v("mdi-delete-outline")])],1):_vm._e(),_vm._v(" "),(
                                    _vm.theInfopack.myPermission &&
                                    _vm.theInfopack.myPermission.userStatus === 2
                                )?_c('v-btn',{attrs:{"depressed":"","color":"blue","icon":"","fab":"","small":""},on:{"click":function($event){$event.stopPropagation();$event.preventDefault();return _vm.$emit('delete-infopack', _vm.theInfopack.id)}}},[_c('v-icon',{staticClass:"font-20 gray-8"},[_vm._v("mdi-delete-outline")])],1):_vm._e(),_vm._v(" "),(
                                    _vm.theInfopack.myPermission && _vm.theInfopack.myPermission.canEdit
                                )?_c('v-btn',{attrs:{"depressed":"","color":"blue","icon":"","fab":"","small":""},on:{"click":function($event){$event.stopPropagation();$event.preventDefault();return _vm.$emit('edit-infopack', _vm.theInfopack.id)}}},[_c('v-icon',{staticClass:"font-20 gray-8"},[_vm._v("mdi-pencil")])],1):_vm._e(),_vm._v(" "),(
                                    _vm.theInfopack.myPermission &&
                                    (_vm.theInfopack.myPermission.canManage ||
                                        _vm.theInfopack.myPermission.canCopy)
                                )?_c('v-menu',{attrs:{"offset-y":"","attach":("#infopack-item-menu-btn-" + (_vm.theInfopack.id)),"min-width":"180","z-index":"2"},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
                                var on = ref.on;
                                var attrs = ref.attrs;
return [_c('v-btn',_vm._g(_vm._b({attrs:{"id":("infopack-item-menu-btn-" + (_vm.theInfopack.id)),"depressed":"","color":"transparent","fab":"","small":""},on:{"click":function($event){$event.stopPropagation();$event.preventDefault();}}},'v-btn',attrs,false),on),[_c('v-icon',{staticClass:"font-20 gray-8"},[_vm._v("mdi-dots-vertical")])],1)]}}],null,true)},[_vm._v(" "),_c('v-list',{staticClass:"simple-menu-list"},[(
                                            _vm.theInfopack.myPermission &&
                                            _vm.theInfopack.myPermission.canManage
                                        )?_c('v-list-item',{staticClass:"simple-menu-item",on:{"click":function($event){return _vm.$emit('publish-infopack', _vm.theInfopack)}}},[_c('v-icon',{staticClass:"me-2 font-20 gray-8"},[_vm._v("mdi-lock-outline")]),_vm._v(" "),_c('span',[_vm._v(_vm._s(_vm.$t('info.public.draftAccesses'))+"...")])],1):_vm._e(),_vm._v(" "),(
                                            _vm.theInfopack.myPermission &&
                                            _vm.theInfopack.myPermission.canCopy
                                        )?_c('v-list-item',{staticClass:"simple-menu-item",on:{"click":function($event){return _vm.$emit('copy-infopack', _vm.theInfopack)}}},[_c('v-icon',{staticClass:"me-2 font-20 gray-8"},[_vm._v("mdi-file-document-multiple-outline")]),_vm._v(" "),_c('span',[_vm._v(_vm._s(_vm.$t('process.actions.copy'))+"...")])],1):_vm._e(),_vm._v(" "),(
                                            _vm.theInfopack.myPermission &&
                                            _vm.theInfopack.myPermission.canManage
                                        )?_c('v-divider'):_vm._e(),_vm._v(" "),(
                                            _vm.theInfopack.myPermission &&
                                            _vm.theInfopack.myPermission.canManage
                                        )?_c('v-list-item',{staticClass:"simple-menu-item",on:{"click":function($event){return _vm.$emit(
                                                'delete-infopack',
                                                _vm.theInfopack.parentId || _vm.theInfopack.id
                                            )}}},[_c('v-icon',{staticClass:"me-2 font-20 red-1"},[_vm._v("mdi-delete")]),_vm._v(" "),_c('span',{staticClass:"red-1"},[_vm._v(_vm._s(_vm.$t('infoPackDetail.actions.delete')))])],1):_vm._e()],1)],1):_vm._e(),_vm._v(" "),(
                                    _vm.theInfopack.myPermission &&
                                    _vm.theInfopack.myPermission.canManage
                                )?_c('v-btn',{attrs:{"depressed":"","icon":""},on:{"click":function($event){$event.stopPropagation();$event.preventDefault();return _vm.$emit('subscription-requests', {
                                        id: _vm.theInfopack.id,
                                        title: _vm.theInfopack.title,
                                    })}}},[_c('v-icon',{staticClass:"gray-8"},[_vm._v("mdi-account-check")])],1):_vm._e()],1):_vm._e()])],1)],1),_vm._v(" "),_c('div',{staticClass:"d-flex align-center px-2"},[_c('v-img',{class:{ logo: !_vm.hasAvatar, circle: _vm.hasAvatar },attrs:{"width":"32","max-width":"32","height":"32","max-height":"32","contain":"","src":_vm.hasAvatar
                            ? ("" + _vm.avatarStorage + (_vm.theInfopack.owner.avatars[0].bucket) + "/" + (_vm.theInfopack.owner.avatars[0].filenameDisk))
                            : '/images/logo-home.png',"alt":"author"}}),_vm._v(" "),(_vm.theInfopack.owner)?_c('span',{staticClass:"font-14 weight-500 info-black mx-2"},[_vm._v("\n                    "+_vm._s(((_vm.theInfopack.owner.firstName) + " " + (_vm.theInfopack.owner.lastName)))+"\n                ")]):_c('span',{staticClass:"font-14 weight-500 info-black mx-2"},[_vm._v("--")]),_vm._v(" "),(_vm.theInfopack.createdAt)?_c('span',{staticClass:"gray-8 font-12"},[_vm._v("\n                    "+_vm._s(_vm.$moment(_vm.theInfopack.createdAt).locale('en').format('LL'))+"\n                ")]):_c('span',{staticClass:"gray-8 font-12"},[_vm._v("-")])],1),_vm._v(" "),_c('div',{staticClass:"d-flex align-center px-2"},[_c('div',[_c('v-icon',{staticClass:"gray-9 font-20 me-1"},[_vm._v("mdi-tag")]),_vm._v(" "),_c('span',{staticClass:"font-12 gray-8 persian-digit"},[_vm._v(_vm._s(_vm.price))])],1),_vm._v(" "),(_vm.theInfopack.issuer)?_c('div',{staticClass:"ms-auto"},[_c('span',{staticClass:"font-12 gray-8"},[_vm._v(_vm._s(_vm.$t('info.public.copiedBy')))]),_vm._v(" "),_c('span',{staticClass:"info-black font-12"},[_vm._v(_vm._s(_vm.theInfopack.issuer.firstName || '')+"\n                        "+_vm._s(_vm.theInfopack.issuer.lastName || ''))])]):_vm._e()]),_vm._v(" "),_c('div',{staticClass:"d-flex align-center px-2"},[_c('v-chip',{staticClass:"green-4",attrs:{"small":"","color":"#ebfcf4"}},[_c('span',{staticClass:"font-14 me-2"},[_vm._v(_vm._s(_vm.$t('info.public.infopack')))]),_vm._v(" "),_c('v-icon',{staticClass:"font-16"},[_vm._v("mdi-package-variant-closed")])],1),_vm._v(" "),_c('div',{staticClass:"d-flex align-center ms-auto"},[(!!_vm.theInfopack.publicPermission)?_c('v-tooltip',{attrs:{"bottom":"","max-width":"500px","color":"rgba(0, 0, 0, 0.87)"},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
                            var on = ref.on;
                            var attrs = ref.attrs;
return [_c('v-icon',_vm._g(_vm._b({staticClass:"iconiz-public"},'v-icon',attrs,false),on))]}}],null,true)},[_vm._v(" "),_c('span',[_vm._v("\n                            "+_vm._s(_vm.$t('info.messages.xIsPublic').replace(
                                    'TITLE',
                                    _vm.$t('info.public.infopack')
                                ))+"\n                        ")])]):_vm._e(),_vm._v(" "),(_vm.theInfopack.participants)?_c('v-tooltip',{attrs:{"bottom":"","max-width":"500px","color":"rgba(0, 0, 0, 0.87)"},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
                                var on = ref.on;
                                var attrs = ref.attrs;
return [_c('v-icon',_vm._g(_vm._b({staticClass:"iconiz-folder-shared"},'v-icon',attrs,false),on))]}}],null,true)},[_vm._v(" "),_c('span',[_vm._v("\n                            "+_vm._s(_vm.$t('info.messages.specialAccessIsSetOnX').replace(
                                    'TITLE',
                                    _vm.$t('info.public.infopack')
                                ))+"\n                        ")])]):_vm._e()],1)],1),_vm._v(" "),_c('div',{staticClass:"d-flex align-center justify-space-between bg-orange-3 pa-2 rounded-sm"},[_c('div',{staticClass:"flex-basis-50 flex-shrink-0"},[_c('p',{staticClass:"font-12 gray-8"},[_c('span',[_vm._v(_vm._s(_vm.$t('info.actions.edit')))]),_vm._v(" "),_c('span',{staticClass:"persian-digit"},[_vm._v(_vm._s(_vm.publishedDate))])])]),_vm._v(" "),(_vm.theInfopack.editedAt || _vm.theInfopack.editedBy)?_c('div',{staticClass:"\n                        flex-basis-50 flex-shrink-0\n                        nowrap\n                        d-flex\n                        align-center\n                        justify-end\n                        font-12\n                        gray-8\n                        overflow-hidden\n                    "},[_c('span',[_vm._v(_vm._s(_vm.$t('info.public.lastEdit'))+":")]),_vm._v(" "),(_vm.theInfopack.editedAt)?_c('span',{staticClass:"persian-digit"},[_vm._v("\n                        "+_vm._s(_vm.mxDateDifferenceFromNow(_vm.theInfopack.editedAt))+"\n                    ")]):_vm._e(),_vm._v(" "),(_vm.theInfopack.editedBy)?_c('p',{staticClass:"text-clamp-1"},[_c('span',[_vm._v(_vm._s(_vm.$t('info.public.by')))]),_vm._v(" "),_c('span',{staticClass:"font-14 info-black"},[_vm._v("\n                            "+_vm._s(((_vm.theInfopack.editedBy.firstName || '') + " " + (_vm.theInfopack.editedBy.lastName || '')))+"\n                        ")])]):_vm._e()]):_vm._e()])])],1)]}}])})}
var staticRenderFns = []

export { render, staticRenderFns }