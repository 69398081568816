import { render, staticRenderFns } from "./participated.vue?vue&type=template&id=fc84d5f2&scoped=true&"
import script from "./participated.vue?vue&type=script&lang=js&"
export * from "./participated.vue?vue&type=script&lang=js&"
import style0 from "./participated.vue?vue&type=style&index=0&id=fc84d5f2&lang=scss&scoped=true&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "fc84d5f2",
  null
  
)

export default component.exports

/* nuxt-component-imports */
installComponents(component, {IProgressCircular: require('/app/components/i/progress/circular.vue').default,ProcessStepsList: require('/app/components/process/steps-list/index.vue').default,IBottomDrawer: require('/app/components/i/bottom-drawer/index.vue').default})


/* vuetify-loader */
import installComponents from "!../../../node_modules/vuetify-loader/lib/runtime/installComponents.js"
import { VBadge } from 'vuetify/lib/components/VBadge';
import { VBtn } from 'vuetify/lib/components/VBtn';
import { VCard } from 'vuetify/lib/components/VCard';
import { VCardText } from 'vuetify/lib/components/VCard';
import { VCardTitle } from 'vuetify/lib/components/VCard';
import { VDivider } from 'vuetify/lib/components/VDivider';
import { VIcon } from 'vuetify/lib/components/VIcon';
import { VList } from 'vuetify/lib/components/VList';
import { VListItem } from 'vuetify/lib/components/VList';
import { VMenu } from 'vuetify/lib/components/VMenu';
import { VTab } from 'vuetify/lib/components/VTabs';
import { VTabs } from 'vuetify/lib/components/VTabs';
import { VTooltip } from 'vuetify/lib/components/VTooltip';
installComponents(component, {VBadge,VBtn,VCard,VCardText,VCardTitle,VDivider,VIcon,VList,VListItem,VMenu,VTab,VTabs,VTooltip})
