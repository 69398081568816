//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

export default {
    props: {
        color: {
            type: String,
            default: 'primary',
        },
    },
};
