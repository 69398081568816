var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-card',{staticClass:"infopack-manage-card pa-2 rounded-lg info-border w-100",attrs:{"elevation":"0"}},[_c('v-card-title',{staticClass:"infopack-manage-card__head d-flex flex-nowrap pa-0"},[_c('nuxt-link',{staticClass:"flex-grow-1 rounded",attrs:{"to":_vm.localePath(_vm.targetUrl)}},[_c('v-icon',{staticClass:"gray-9 font-16"},[_vm._v(_vm._s(_vm.icon))]),_vm._v(" "),_c('v-tooltip',{attrs:{"top":"","disabled":!_vm.$breakpoint.mdAndUp || !_vm.theInfopack.title || _vm.theInfopack.title.length < 60,"max-width":"500px","color":"rgba(0, 0, 0, 0.87)"},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('p',_vm._g(_vm._b({staticClass:"ps-2 text-clamp-1 font-16 info-black"},'p',attrs,false),on),[_vm._v("\n                        "+_vm._s(_vm.theInfopack.title || _vm.$t('info.public.untitled'))+"\n                    ")])]}}])},[_vm._v(" "),_c('span',[_vm._v(_vm._s(_vm.theInfopack.title))])])],1),_vm._v(" "),(_vm.theInfopack.myPermission)?_c('div',{staticClass:"actions d-flex justify-center align-center flex-grow-0"},[(_vm.theInfopack.myPermission.canManage || _vm.theInfopack.myPermission.canCopy)?_c('v-menu',{attrs:{"offset-y":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-badge',{attrs:{"bordered":"","dot":"","offset-x":"12","offset-y":"16","color":"error","overlap":"","value":_vm.theInfopack.myPermission.canManage &&
                            _vm.theInfopack.subscriptionRequestCount > 0}},[_c('v-btn',_vm._g(_vm._b({attrs:{"elevation":"0","color":"transparent","fab":"","small":""}},'v-btn',attrs,false),on),[_c('v-icon',{staticClass:"iconiz-dots-vertical"})],1)],1)]}}],null,false,859990010)},[_vm._v(" "),_c('v-list',{staticClass:"simple-menu-list"},[(_vm.theInfopack.myPermission.canManage)?_c('v-list-item',{staticClass:"simple-menu-item",on:{"click":function($event){return _vm.$emit('subscription-requests', {
                                id: _vm.theInfopack.id,
                                title: _vm.theInfopack.title,
                            })}}},[_c('v-badge',{attrs:{"bordered":"","dot":"","offset-x":"24","offset-y":"10","color":"error","overlap":"","value":_vm.theInfopack.subscriptionRequestCount > 0}},[_c('v-icon',{staticClass:"me-2 font-20 gray-8"},[_vm._v("mdi-account-check")])],1),_vm._v(" "),_c('span',[_vm._v(_vm._s(_vm.$t('info.public.usersList')))])],1):_vm._e(),_vm._v(" "),(_vm.theInfopack.myPermission.canManage)?_c('v-list-item',{staticClass:"simple-menu-item",on:{"click":function($event){return _vm.$emit('publish-infopack', _vm.theInfopack)}}},[_c('v-icon',{staticClass:"me-2 font-20 gray-8"},[_vm._v("mdi-lock-outline")]),_vm._v(" "),_c('span',[_vm._v(_vm._s(_vm.$t('info.public.draftAccesses'))+"...")])],1):_vm._e(),_vm._v(" "),(_vm.theInfopack.myPermission.canCopy)?_c('v-list-item',{staticClass:"simple-menu-item",on:{"click":function($event){return _vm.$emit('copy-infopack', _vm.theInfopack)}}},[_c('v-icon',{staticClass:"me-2 font-20 gray-8"},[_vm._v("mdi-file-document-multiple-outline")]),_vm._v(" "),_c('span',[_vm._v(_vm._s(_vm.$t('info.actions.copy'))+"...")])],1):_vm._e(),_vm._v(" "),(_vm.theInfopack.myPermission.canManage)?_c('v-divider'):_vm._e(),_vm._v(" "),(_vm.theInfopack.myPermission.canManage)?_c('v-list-item',{staticClass:"simple-menu-item remove me-2 font-20",on:{"click":function($event){return _vm.$emit('delete-infopack', _vm.theInfopack.parentId || _vm.theInfopack.id)}}},[_c('v-icon',{staticClass:"iconiz-delete"}),_vm._v(" "),_c('span',[_vm._v(_vm._s(_vm.$t('info.actions.delete')))])],1):_vm._e()],1)],1):_vm._e()],1):_vm._e()],1),_vm._v(" "),_c('div',{staticClass:"infopack-manage-card__image"},[_c('v-img',{staticClass:"infopack__item__image flex-grow-0 rounded px-2 py-1",attrs:{"width":"100%","aspect-ratio":16 / 10,"src":_vm.theInfopack.infopackAsset
                    ? (_vm.storage + "/" + (_vm.theInfopack.infopackAsset.filenameDisk))
                    : '/images/default-image.jpg',"lazy-src":'/images/default-image.jpg'}})],1),_vm._v(" "),_c('v-card-text',{staticClass:"d-flex align-center justify-start persian-digit px-2 py-1"},[_c('div',{staticClass:"d-flex align-center"},[_c('v-icon',{staticClass:"gray-9 font-16 iconiz-sell me-2"}),_vm._v(" "),_c('span',{staticClass:"font-12 gray-8 persian-digit"},[_vm._v(_vm._s(_vm.price))])],1),_vm._v(" "),(_vm.theInfopack.duration)?_c('div',{staticClass:"d-flex align-center mx-2"},[_c('v-icon',{staticClass:"gray-9 font-16 iconiz-date-range-outline"}),_vm._v(" "),_c('span',{staticClass:"font-12 gray-8 px-1"},[_vm._v("\n                "+_vm._s(_vm.theInfopack.duration)+"\n                "+_vm._s(_vm.theInfopack.duration > 1 ? _vm.$t('dates.months') : _vm.$t('dates.month'))+"\n            ")])],1):_vm._e(),_vm._v(" "),(_vm.theInfopack.issuer)?_c('div',{staticClass:"d-flex align-center ms-auto"},[_c('span',{staticClass:"font-12 gray-8 px-1"},[_vm._v(_vm._s(_vm.$t('process.copiedBy')))]),_vm._v(" "),_c('span',{staticClass:"font-12 gray-8 px-1 info-black"},[_vm._v("\n                "+_vm._s(_vm.theInfopack.issuer.firstName || '')+" "+_vm._s(_vm.theInfopack.issuer.lastName || '')+"\n            ")])]):_vm._e()]),_vm._v(" "),_c('v-card-text',{staticClass:"d-flex align-center persian-digit px-2 py-1"},[_c('span',{staticClass:"font-12 gray-8"},[_vm._v(_vm._s(_vm.publishedDate))]),_vm._v(" "),(_vm.theInfopack.access)?_c('v-tooltip',{attrs:{"bottom":"","max-width":"500px","color":"rgba(0, 0, 0, 0.87)"},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
                    var on = ref.on;
                    var attrs = ref.attrs;
return [_c('v-icon',_vm._g(_vm._b({staticClass:"iconiz-public gray-9 ms-4 font-20"},'v-icon',attrs,false),on))]}}],null,false,410320807)},[_vm._v(" "),_c('span',[_vm._v(_vm._s(_vm.$t('infoPackDetail.publicAccess')))])]):_vm._e(),_vm._v(" "),(_vm.theInfopack.participants)?_c('v-tooltip',{attrs:{"bottom":"","max-width":"500px","color":"rgba(0, 0, 0, 0.87)"},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
                    var on = ref.on;
                    var attrs = ref.attrs;
return [_c('v-icon',_vm._g(_vm._b({staticClass:"iconiz-folder-shared"},'v-icon',attrs,false),on))]}}],null,false,2179898582)},[_vm._v(" "),_c('span',[_vm._v(_vm._s(_vm.$t('infoPackDetail.specialAccess')))])]):_vm._e()],1),_vm._v(" "),(_vm.theInfopack.editedAt || _vm.theInfopack.editedBy)?_c('v-card-text',{staticClass:"bg-orange-3 d-flex align-center g-1 rounded persian-digit px-2 py-1"},[_c('p',{staticClass:"font-12 gray-8 nowrap"},[_vm._v(_vm._s(_vm.$t('process.lastEdit'))+":")]),_vm._v(" "),(_vm.theInfopack.editedAt)?_c('p',{staticClass:"font-12 gray-8 nowrap"},[_vm._v("\n            "+_vm._s(_vm.mxDateDifferenceFromNow(_vm.theInfopack.editedAt))+"\n        ")]):_vm._e(),_vm._v(" "),(_vm.theInfopack.editedBy)?_c('p',{staticClass:"font-12 gray-8 text-clamp-1"},[_c('span',[_vm._v(_vm._s(_vm.$t('public.by')))]),_vm._v(" "),_c('span',{staticClass:"font-14 info-black"},[_vm._v("\n                "+_vm._s(((_vm.theInfopack.editedBy.firstName || '') + " " + (_vm.theInfopack.editedBy.lastName || '')))+"\n            ")])]):_vm._e()]):_vm._e(),_vm._v(" "),_c('v-card-text',{staticClass:"d-flex align-center py-1 px-2"},[_c('div',{staticClass:"avatar"},[(
                    _vm.theInfopack.owner &&
                    _vm.theInfopack.owner.avatars &&
                    _vm.theInfopack.owner.avatars.length
                )?_c('v-img',{staticClass:"circle",attrs:{"width":"32","height":"32","src":("" + _vm.avatarStorage + (_vm.theInfopack.owner.avatars[0].bucket) + "/" + (_vm.theInfopack.owner.avatars[0].filenameDisk)),"alt":""}}):_c('v-img',{staticClass:"logo",attrs:{"contain":"","height":"32","width":"32","src":'/images/logo-home.png',"alt":""}})],1),_vm._v(" "),_c('div',{staticClass:"d-flex align-center mw-50 mx-2"},[(_vm.theInfopack.owner)?_c('span',{staticClass:"text-clamp-1 font-14 info-black weight-500"},[_vm._v("\n                "+_vm._s(((_vm.theInfopack.owner.firstName) + " " + (_vm.theInfopack.owner.lastName)))+"\n            ")]):_c('span',{staticClass:"font-14 info-black weight-500"},[_vm._v("--")])]),_vm._v(" "),_c('div',[(_vm.theInfopack.createdAt)?_c('span',{staticClass:"font-12 gray-8"},[_vm._v("\n                "+_vm._s(_vm.$moment(_vm.theInfopack.createdAt).locale('en').format('LL'))+"\n            ")]):_c('span',[_vm._v("-")])])])],1)}
var staticRenderFns = []

export { render, staticRenderFns }