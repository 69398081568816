import { Vue, Component, Action, Prop } from 'nuxt-property-decorator';
import LazyHydrate from 'vue-lazy-hydration';
import { EventBus } from '@/utils/event-bus';

const OFFSET = 5;

@Component({
    name: 'HomeGuestScript',
    components: {
        LazyHydrate,
    },
})
export default class HomeGuestScript extends Vue {
    @Prop({ required: true })
    homeData;

    public modaller = {
        width: '500',
        persistent: false,
    };

    public mainSlider;

    public sliderAssetBaseUrl = process.env.VUE_APP_API_STORAGE_URL;

    public suggestedContent = {
        data: [],
        groupedData: [],
        loading: false,
        limit: OFFSET,
        offset: OFFSET,
    };

    @Action('setSnackBar') setSnackBar;

    mounted() {
        this.suggestedContent.data = this.homeData.suggested.docs;

        this.mainSlider = this.homeData?.sliders?.banner?.[0];

        this.groupBuilder(this.homeData.suggested.docs);
    }

    openLoginRegisterModal() {
        this.$refs.modaller.openModal('ModalLoginRegister', {}, {});
    }

    openSearchBox() {
        EventBus.$emit('open-search');
    }

    groupBuilder(newData) {
        try {
            if (!newData || !newData.length) return;

            const chunkSize = 3;
            for (let i = 0; i < newData.length; i += chunkSize + 2) {
                this.suggestedContent.groupedData.push(...newData.slice(i, chunkSize));
                this.suggestedContent.groupedData.push([newData[i + 3], newData[i + 4]]);
            }
        } catch (error) {
            // TODO handle error
        }
    }

    async getMoreSuggestedContent() {
        if (
            this.suggestedContent.offset > this.homeData.suggested.total ||
            this.suggestedContent.loading
        ) {
            return;
        }
        this.suggestedContent.loading = true;
        try {
            this.suggestedContent.offset += OFFSET;
            const response = await this.$homeService.getSuggestedContent(
                this.suggestedContent.limit,
                this.suggestedContent.offset
            );
            this.suggestedContent.data.push(...response.docs);
            this.groupBuilder(response.docs);
        } catch (e) {
            this.suggestedContent.offset -= OFFSET;
            this.setSnackBar({
                toggle: true,
                message:
                    e?.response?.data?.notification?.message ||
                    e?.data?.notification?.message ||
                    e?.message ||
                    this.$t('info.messages.anErrorOccurred'),
                color: 'red',
            });
        }
        this.suggestedContent.loading = false;
    }
}
